<template>
  <v-card>
    <v-card-title data-cy="billing-details-card-title">
      <span v-t="'billing.billingDetails'" />
      <v-spacer />
      <material-billing-edit-details-modal-window
        v-if="!invoiceToPartner"
        :title="$t('billing.checkoutEditBillingInfo')"
        :client-id="clientId"
        :on-submit="setBillingDetails"
      />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row
        v-if="billingDetails"
        class="flex-row-reverse"
        data-cy="billing-details-card"
      >
        <v-col
          v-for="{ key, labelKey } in BILLING_DETAIL_LABELS"
          :key="key"
          class="pt-0"
          cols="12"
          sm="6"
        >
          <div class="overline">
            <!-- eslint-disable-next-line vue-i18n/no-dynamic-keys -->
            <span v-t="labelKey" />
          </div>

          <div :data-cy="`edited-billing-${key}`">
            {{ key === 'state' ? billingDetails.state.value : billingDetails[key] }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pt-0"
        >
          <div class="overline">
            <span v-t="'billing.vat'" />
          </div>

          <div :data-cy="`edited-billing-vat`">
            {{ billingDetails.vat }}
          </div>
          <core-element-info-message
            class="mt-2 mb-0"
            :message="$t('billing.euCompaniesWarning')"
          />
        </v-col>
      </v-row>

      <div
        v-else
        v-t="'billing.youDoNotHaveSavedBillingDataYet'"
        class="text-center text--secondary"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { BILLING_DETAIL_LABELS } from '@/constants/app'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    },
    invoiceToPartner: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      BILLING_DETAIL_LABELS
    }
  },
  computed: {
    ...mapGetters('client', ['getBillingDetailDataById', 'getPartnerBillingDetailDataById']),
    ...mapState('billingDetails', ['countryCodes']),
    billingDetails () {
      return this.invoiceToPartner
        ? this.getPartnerBillingDetailDataById(this.clientId)
        : this.getBillingDetailDataById(this.clientId)
    }
  },
  methods: {
    ...mapActions('billingDetails', ['setBillingDetails']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
